$primary-light: rgb(218,201,201);
$primary-light-opaque: rgb(218,201,201, .24); 
$primary-med: #878a91;
// $primary-blue: #2e3035;
$primary-dark: #262731;
$primary-blue: rgb(0, 43, 73);
$primary-gray: rgb(221, 229, 237);
$primary-orange: #FF7B00;
$primary-bg: #EFF3F7;

$bg-header: #EDF1F8; // TODO swap these?
$bg-header2: #EFF2F6; // TODO swap these?
$primary-border-color: #E2E7ED;

$header-font-blue: rgba(59, 82, 102, 1);

$bg-dark: #2e3035;
$bg-light: #E5E5E5;
$bg-mid: #EFF3F7;
$tooltip-gray: rgba(103, 117, 138, 1);

$std-width: min(1200px, 90vw);
$std-height: calc(100vh - 80px);

$border-light: rgba(195,181,181,0.55);

$primary-red: #BC2134;
$primary-yellow: #FFD763;
$primary-green: #2D822F;

$score0: rgba(103, 117, 138, 0.4);
$score1: $primary-green;
$score2: #ADC034;
$score3: $primary-yellow;
$score4: #FF9E44;
$score5: $primary-red;

.bg0 {
  background: $score0;
  color: black;
}
.bg1 {
  background: $score1;
  color: white;
}
.bg2 {
  background: $score2;
  color: white;
}
.bg3 {
  background: $score3;
}
.bg4 {
  background: $score4;
  color: white;
}
.bg5 {
  background: $score5;
  color: white;
}

.border0 {
  border: 2px solid $score0;
}
.border1 {
  border: 2px solid $score1;
}
.border2 {
  border: 2px solid $score2;
}
.border3 {
  border: 2px solid $score3;
}
.border4 {
  border: 2px solid $score4;
}
.border5 {
  border: 2px solid $score5;
}

$orange-gradient: linear-gradient(77.39deg, #F76E15 14.98%, #FABA25 91.41%);

.neg-text {
  color: $score5;
}
.pos-text {
  color: $score1;
}
.neutral-text {
  color: $score2;
}

.help-icon {
  font-size: 20px;
  color: gray;
  cursor: pointer;
}

$primary-font: 'Rubik';
.header-font {
  font-family: $primary-font;
  color: $header-font-blue;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 16px;
  gap: 8px;
  border: 1px solid #E2E7ED;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  .card-header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    height: 58px;
    &.no-pad {
      padding: 0px;
    }
    gap: 775px;
    background: #EFF2F6;
    border-bottom: 1px solid #E2E7ED;
    flex: none;
    order: 0;
    flex-grow: 0;
    &.no-header {
      background: white;
      border-bottom: none;
    }
  }
  .card-content {
    width: 100%;
    min-height: 100px;
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
    &.no-pad {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
.card-title {
  /* subtitle2 - Medium */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-align: center;
  /* text/primary */
  color: #1E2126;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0; 
  .tooltip-icon {
    vertical-align: middle;
    margin-left: 14px;
    height: 24px;
    width: 24px;
    font-size: 22px;
  }
}
.tooltip-icon, .help-icon {
  color: $tooltip-gray;
  vertical-align: middle;
  margin-left: 10px;
}
.score-value {
  font-weight: 500;
  font-size: 18px;
}
.score-0 { color: $score0; span { color: $score0;}}
.score-1 { color: $score1; span { color: $score1;}}
.score-2 { color: $score2; span { color: $score2;}}
.score-3 { color: $score3; span { color: $score3;}}
.score-4 { color: $score4; span { color: $score4;}}
.score-5 { color: $score5; span { color: $score5;}}


// .bordered {
//   border: 2px solid rgba(226, 231, 237, 1);
//   border-radius: 8px;
//   padding: 10px;
// }
.left-bordered {
  border-left: 2px solid $primary-border-color;
}

.no-underline {
  .mat-form-field-underline {
    display: none !important;
  }
}
.primary-blue {
  color: $primary-blue !important;
}

.pub, .wos, .ieee, .cordis_publications {
  color: rgb(201,201,237);
}
.grant, .cordis_grants, .nih, .nsf, .sbir {
  color: rgb(53,231,53);
}
.pat, .dwpi, .epodocdb_family {
  color: rgb(206,118,55);
}
.ct, .clinicaltrials {
  color: orange;
}

.ngx-charts text {
  fill: $primary-light;
  &:hover {
    fill: $primary-blue;
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

/**
ng-mat overrides
*/
/** change the height of button-toggle-groups */
.group-controls {
  .add-group, .add-clause, .mat-button-toggle-label-content {
    height: 24px;
    line-height: 24px !important;
  }
}
.view-toggle-group {
  .mat-button-toggle-label-content {
    height: 35px;
    line-height: 35px !important;
  }
}
    

.primary-light {
  &.mat-button-disabled {
    background-color: $primary-light !important;
  }
  .mat-focused .mat-form-field-label {
    color: $primary-light !important;
  }
  .mat-form-field-can-float {
    color: $primary-light !important;
  }
  .mat-form-field-underline {
    background-color: $primary-light !important;
  } 
  .mat-form-field-label .ng-star-inserted {
    color: $primary-light;
  }
  .mat-form-field-ripple {
    background-color: $primary-light !important;;
  }
  .mat-tab-link {
    color: $primary-light;
    &:hover {
      color: $primary-blue;
      background-color: $bg-light;
    }
  }
  .mat-tab-nav-bar, .mat-tab-header {
    border-bottom: 1px solid $primary-light-opaque;
  }
}

.slide-in-left {
  animation: slide-in-left 1s forwards;
  -webkit-animation: slide-in-left 1s forwards;
}

.slide-in-right {
  animation: slide-in-right 1s forwards;
  -webkit-animation: slide-in-right 1s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
  
@keyframes slide-in-left {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in-left {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-in-right {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in-right {
  100% { -webkit-transform: translateX(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}

.ing-option {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    display: flex;
  }
}

.skeleton {
  min-height: 100px;
  display: grid;
  align-content: center;
}