
@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 density: -3,
));
@include mat.core-theme($my-theme);


/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css?family=Proxima+Nova');
@import "/node_modules/flag-icons/css/flag-icons.min.css";
@import '~@aws-amplify/ui-angular/theme.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// @import '~@angular/material/theming';
// $custom-typography: mat-typography-config(
//   $font-family: 'Proxima Nova'
// );
// @include mat-core($custom-typography);